<template>
  <top-nav :activeTab="activeTab"></top-nav>

  <div class="mainContainer container" style="">



    <div id="community" v-if="activeTab === 'community'">

      <div class="info" v-if="activeTab === 'community'">
        <div><img class="logo" src="/images/logo_golden_wo_text.svg" alt=""></div>
        <div>Transactions shared by the Goblin Bank families. Get ready to be inspired!</div>
      </div>

      <hr>

      <div id="transDiv">
        <div class="cTran" v-for="tran in trans" :key="tran.ID">
          <c-tran :tran="tran" @thumbsUp="thumbsUp"></c-tran>
        </div>

        <div class="alert gotoBtnDiv clickable" @click="goToUserPage(trans[0].USER_ID)" v-if="activeTab !== 'community'">More transactions are available at each member's banking center.</div>
      </div>



    </div>



  </div>




</template>

<script>

import cTran from '@/components/Insights/cTran';
import TopNav from '@/components/Insights/TopNav';

var d = new Date();

export default {
  name: 'Insights',
  components: {
    cTran,
    TopNav,
    //Carousel,
    //Slide,
  },
  data(){
    return{
      //activeTab:'last30days',
      activeTab:'community',
      trans:[],
      familyUsers:[],
      activeTranId:null,
      showTranDetailsModal:false,
      shortLength:120,

      tabs:[
        {'tab':'last30days', 'name':'Last 30 Days'},
        {'tab':'next30days', 'name':'Next 30 Days'},
        {'tab':'community', 'name':'Community'},
        {'tab':'articles', 'name':'Articles'}
      ],
    }
  },

  computed:{
    activeTran(){
      return this.activeTranId ? this.trans.filter(t=>t.ID === this.activeTranId)[0] : {};
    }
  },


  methods:{

    getKidsTrans: function(){
      let loader = this.$loading.show();
      this.axios
          .post(this.$hostApi,{task:'getKidsTrans',type:this.activeTab,today:d.toLocaleDateString('en-CA')},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.trans = response.data;
            loader.hide();
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },



    getUser(userId){
      return this.familyUsers.filter(user => user.ID === userId)[0];
    },

    getUserAvatar(userId){
      var user = this.familyUsers.filter(user => user.ID === userId)[0];
      //console.log(user.AVATAR_URL);
      return user.AVATAR_URL;
    },

    numberColor:function(num){
      return num < 0 ? 'negative' : 'positive';
    },

    inout:function(num){
      return num < 0 ? 'spent' : 'received';
    },

    openTranDetails(tranId){
      this.activeTranId = tranId;
      this.showTranDetailsModal=true;
    },
    closeTranDetailsModal(){
      this.activeTranId = null;
      this.showTranDetailsModal=false
    },
    goToUserPage(userId){
      this.$root.gotoRoute({ path: '/member', query: { userId: userId } })
    },



    genDateHtml(d){
      var parts = d.split('-');
      const date = new Date();
      date.setMonth(parseInt(parts[1]))
      date.toLocaleString('en-US', { month: 'narrow' })
      return "<div><div class='cDay'>"+parts[2]+"</div><div class='cMonth'>"+date.toLocaleString('en-US', { month: 'short' })+"</div><div class='cYear'>"+parts[0]+"</div></div>";
    },

    thumbsUp(tranId){
      this.activeTranId = tranId;
      this.activeTran.THUMBS_UP++;
    },

    truncateString(str, n) {
      if (str.length > n) {
        return str.substring(0, n) + "...";
      } else {
        return str;
      }
    },



  },
  created() {
    this.familyUsers = JSON.parse(localStorage.getItem('familyUsers'));
  },
  mounted() {
    document.title = "Community";
    this.getKidsTrans();
  },

}
</script>

<style scoped>






  .mainContainer{
    margin-top:150px;
  }


  .logo{
    width:40px;
    height:40px;
    padding:3px;
    border-radius: 50%;
    border:1px solid #0dcaf0;
    filter: invert(68%) sepia(64%) saturate(2311%) hue-rotate(145deg) brightness(97%) contrast(95%);
    margin-bottom:10px;
  }

  .info{
    font-size:20px;
    text-align: center;

  }

  #transDiv{
    margin-top:30px;
  }

  .tran{
    height:110px;
    box-shadow: 1px 0 2px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
    margin:15px 0;
    border-radius: 3px;
    background-color: #f6f6c6;
  }

  .tranDate{
    text-align: center;
    font-size:12px;
    background-color: #a4a415;
    color:#ffffff;
    padding:5px;
  }

  .sharedIcon{
    float:right;
    margin-right:10px;
  }

  .tranBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
  }





  .leftDiv{
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .avatar{
    margin-left:5px;
    width:65px;
  }

  .avatar img{
    width:60px;
    height:60px;
    border:2px solid #9c9c9c;
    border-radius: 50%;
    padding:3px;
  }

  .desc{
    margin-left:20px;
    font-size:11px;
    width:calc(100% - 165px);
  }



  .amount{
    font-size:18px;
    width:100px;
    text-align: right;
  }

  .currencyCode{
    font-size:9px;
    text-align: right;
  }






  .gotoBtnDiv{
    background-color: #b7fa8b;
  }


  .lightBulb{
    color:orange;
    margin-right:10px;
  }

  .shareDiv{
    margin-top:20px;
    background-color: #b7fa8b;
    display: flex;
    justify-content: left;
    align-items: center;
    padding:10px;
    border-radius: 3px;

  }

  .shareIconDiv{
    padding:0 20px 0 10px;
    font-size:20px;
    color:orange;
  }



  /*modal*/
  .modalParent :deep(.modal-container) {
    width:100%;
    top:20px;

  }
  .modalParent :deep(.modal-content){
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem !important;
    padding: 1rem !important;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #f6f6c6;
    width:unset;

  }
  .modal__title {
    margin: 0 2rem 0 0;
    font-size: 14px;
    font-weight: 700;
  }
  .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border: 0 solid #e2e8f0;
  }

  .modal-header{
    display: none;
  }


  .btn.closeModal {
    background-color: #e3e1e1;
    border: 0;
    padding:5px 10px;
    margin-top:20px;
  }

/*end of modal */


</style>